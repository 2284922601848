import { GallerySlider } from '@/core/blocks';
import { Icon } from '@/core/icon';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  HtmlMarkup,
  Image,
  Typography,
} from '@/core/ui';
import { cn } from '@/lib';
import { API } from '@/store/api';
import { cx } from 'class-variance-authority';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ObjectDetailSkeleton } from './view.skeleton';
import { useSendCommandMutation } from '@/store/onecore/api';
import { useState, useRef, useEffect } from 'react';

export const ObjectDetails = () => {
  const { id, screen_id } = useParams();
  const { i18n } = useTranslation('translation');

  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const [scrollSections, setScrollSections] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showGradient, setShowGradient] = useState(true);
  const [gradientPosition, setGradientPosition] = useState(0);
  let scrollTimeout: NodeJS.Timeout;

  const {
    isLoading,
    isFetching,
    data: object,
    error,
  } = API.objectApi.useGetObjectByIdQuery(
    { id: Number.parseInt(id as string), locale: i18n.language },
    { skip: !id || !i18n.language },
  );

  const [sendCommand] = useSendCommandMutation();

  const { data: configData } = API.oneCoreApi.useGetOneCoreConfigQuery();

  useEffect(() => {
    const container = scrollableRef.current;
    if (container) {
      const clientHeight = container.clientHeight;
      const scrollHeight = container.scrollHeight;
  
      const sections = Math.floor(scrollHeight / clientHeight);
      const leftover = scrollHeight % clientHeight;
  
      const adjustedSections = leftover > clientHeight * 0.1 ? sections + 1 : sections;
  
      setScrollSections([...Array(adjustedSections).keys()]);
    }
  }, [object?.description]);
  

  const handleDotClick = (index: number) => {
    if (scrollableRef.current) {
      const clientHeight = scrollableRef.current.clientHeight;
      scrollableRef.current.scrollTo({
        top: index * clientHeight,
        behavior: 'smooth',
      });
      setSelectedIndex(index);
    }
  };

  const handleScroll = () => {
    if (scrollableRef.current) {
      const { scrollTop, clientHeight, scrollHeight } = scrollableRef.current;
      const index = Math.round(scrollTop / clientHeight);
      setSelectedIndex(index);
      setShowGradient(false);
      setGradientPosition(scrollTop);

      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        setShowGradient(scrollTop + clientHeight < scrollHeight);
      }, 150);
    }
  };

  const handleSendZero = async () => {
    if (!configData?.data?.settings) {
      console.error('Configuration data is not available.');
      return;
    }

    try {
      const { IP_ADDRESS, TOKEN } = configData.data.settings;

      await sendCommand({
        app: 'Objects',
        address: IP_ADDRESS,
        key: TOKEN,
        options: {
          command: 'setChannelValue',
          channel: object?.shelly?.channel ?? 0,
          value: 0,
        },
        devices: [Number(object?.shelly?.device_id) ?? 0],
      }).unwrap();

      console.log('Command sent successfully');
    } catch (error) {
      console.error('Failed to send command:', error);
    }
  };

  if (error) {
    return <p>Oh no, there was an error</p>;
  }

  if (isLoading || isFetching || !object) {
    return <ObjectDetailSkeleton />;
  }

  return (
    <div className="relative grid bg-apple h-full w-full shadow-container grid-cols-8 rounded-2xl">
      <div
        className={cx(
          'bg-contain bg-bottom bg-trophy bg-olive',
          'rounded-tl-2xl rounded-bl-2xl col-span-3 relative',
        )}
      >
        {!!object.medias.length && (
          <Dialog>
            <DialogTrigger asChild>
              <button
                type="button"
                className="absolute top-[49%] rounded-full bg-chalk/20 left-[6.2rem] backdrop-blur-lg animate-pulse-reflection"
              >
                <Icon name="PLUS" />
              </button>
            </DialogTrigger>
            <DialogContent
              closeJsx={
                <DialogClose className="absolute right-8 top-8 rounded-sm ring-offset-background bg-transparent transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
                  <Icon name={'X_ICON'} className="bg-danger rounded-full p-2 w-16 h-16" color="white" />
                  <span className="sr-only">Close</span>
                </DialogClose>
              }
              className="bg-transparent flex justify-center items-center shadow-none"
            >
              <DialogTitle />
              <GallerySlider slides={object.medias} />
            </DialogContent>
          </Dialog>
        )}
        <div className="flex justify-center h-full items-center">
          <Image src={object.image.path} alt={object.name} className="h-auto w-[80%]" />
        </div>
      </div>
      <Link
        to={`/${screen_id}`}
        className={cn(
          'absolute top-8 right-8',
          'flex px-6 py-3.5 bg-danger rounded-full items-center space-x-1.5',
        )}
        onClick={handleSendZero}
      >
        <Icon className="h-4 w-4" name="CHEVRON_LEFT" />
        <Icon className="h-5 w-5" name="HOME" />
      </Link>
      <div className="px-28 pb-24 pt-28 flex flex-col justify-between col-span-5">
        <div className="flex flex-col space-y-14">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <Typography
                className={'font-bold uppercase max-w-2xl leading-[44px] tracking-[3px] text-5xl'}
              >
                {object.name}
              </Typography>
            </div>
          </div>
          <div className="max-w-[44rem] flex flex-col relative">
            <div
              className="overflow-y-auto max-h-[26rem] relative scrollbar scrollbar-w-2 scrollbar-thumb-[#C61D23] scrollbar-track-[#A6A5A4] scrollbar-thumb-rounded scrollbar-track-rounded scroll-p-5"
              ref={scrollableRef}
              onScroll={handleScroll}
            >
              <HtmlMarkup className="prose text-[26px] font-united-sans font-medium tracking-[1.2px] leading-8 flex flex-col space-y-4">
                {object.description}
              </HtmlMarkup>
              {showGradient && (
            <div
              className='absolute left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent pointer-events-none'
              style={{ bottom: -gradientPosition }}
            />
          )}
            </div>
            <div className="absolute -right-8 top-0 bottom-0 flex flex-col items-center justify-center space-y-2">
              {scrollSections.map((_, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => handleDotClick(index)}
                  className={cn(
                    'w-4 h-4 rounded-full bg-gray-300 ',
                    index === selectedIndex && 'bg-[#C61D23]'
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
